// Module
var code = `<template>
  <require from="./conditions.scss"></require>
  <section id="conditions" class="flex-column">
    <!-- <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="/static/img/arrow_left.svg"
      />
      <div class="flex-grow"></div>
      <h1 i18n="legal.conditions.tabtitle"></h1>
      <div class="flex-grow"></div>
    </div> -->
    <div class="listing" click.delegate="router.navigateToRoute('terms')">
      <div class="object flex-row">
        <i class="fas fa-book"></i>
        <p i18n="legal.conditions.conditions2">Conditions de Banka</p>
      </div>
      <div class="object flex-row" onclick="window.location.href = 'https://www.yapily.com/legal/yapilyconnect-terms-and-conditions/';">
        <i class="fas fa-book"></i>
        <p i18n="legal.conditions.conditions3">Conditions de Yapily</p>
      </div>
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;